import React, { useState } from "react";
import PageHeader from "./PageHeader";

const ServiceDescription = ({ title, description, servicesList, sedanPrice, vanPrice }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to split prices into two cells if multiple prices are present
  const renderPriceCells = (price) => {
    const prices = price.split("/"); // Split the price string by "/"
    if (prices.length === 2) {
      return (
        <td className="custom-td">
        {prices.map((p, index) => <td className={`custom-td-${index}`} key={index}>{p}</td>)}
        </td>
      ); // Render each price in a separate <td>
    }
    return (
      <>
        <td>{price}</td>
      </>
    );
  };

  return (
    <div className="col-md-6 mb-md-0 mb-4">
      <h3>{title}</h3>
      <p className="lead mb-md-5 mb-4">{description}</p>
      {servicesList.map((item, index) => {
        const [heading, details] = item.split(":");
        return (
          <p key={index} className="serviceDesc-p">
            <span className="highlighted-heading">{heading}:</span> {details}
          </p>
        );
      })}

      <div className="price-div">
        <button onClick={toggleDropdown} className="check-price-btn">
          Check Price <span className="dropdown-icon">&#9662;</span>
        </button>
        <div
          className={`price-dropdown ${isDropdownOpen ? "show" : ""}`}
          style={{ display: isDropdownOpen ? "block" : "none" }}
        >
          <table>
            <thead>
              <tr>
                <th colSpan="2">Personal Vehicle</th>
                <th>Commercial Vehicle</th>
              </tr>
              <tr className="custom-tr-vehicle-type">
                <th>Sedan/SUV</th>
                <th>Pickup/Van</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {renderPriceCells(sedanPrice)}
                {renderPriceCells(vanPrice)}
                <td>Contact Us</td> {/* Commercial Vehicle price */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ServiceImg = ({ imageSrc }) => {
  return (
    <div className="col-md-6">
      <div className="blur-shadow-image text-center">
        <img
          src={imageSrc}
          alt="Service"
          className="img-fluid shadow-xl border-radius-lg max-height-550"
        />
      </div>
    </div>
  );
};

const ServiceElement = ({ id, title, description, imageSrc, servicesList, sedanPrice, vanPrice }) => {
  const isOdd = id % 2 !== 0; // Check if id is odd or even

  return (
    <div className="container py-5">
      <div className={`row align-items-center ${isOdd ? "flex-row-reverse" : ""}`}>
        <ServiceDescription
          title={title}
          description={description}
          servicesList={servicesList}
          sedanPrice={sedanPrice}
          vanPrice={vanPrice}
        />
        <ServiceImg imageSrc={imageSrc} />
      </div>
    </div>
  );
};

const Services = () => {
  const serviceData = [
    {
      id: 1,
      title: "REGULAR INTERIOR DETAILING",
      description:
        "Keep your vehicle’s interior feeling new and comfortable. This service eliminates dust, dirt, and stains, refreshing every surface for a cleaner, healthier environment.",
      imageSrc: "/images/interior.webp",
      servicesList: [
        "Deep Vacuuming: Removes dust and allergens from vehiclepets, seats, and mats.",
        "Upholstery Shampoo: Restores fabric, lifting stains and odors.",
        "Leather vehiclee: Protects and softens leather for a luxurious, long-lasting finish.",
        "Streak-Free Glass Cleaning: Improves visibility and keeps windows crystal-clear.",
        "Dashboard and Trim Polishing: Preserves surfaces with a polished, new-like appearance.",
      ],
      sedanPrice: "$70",
      vanPrice: "$70",
    },
    {
      id: 2,
      title: "CERAMIC COATING",
      description:
        "Protect your vehicle’s paint with advanced ceramic technology. This service provides a long-lasting, glossy shield against environmental damage, reducing maintenance and keeping your vehicle looking brand new.",
      imageSrc: "/images/ceramiCoating.webp",
      servicesList: [
        "Long-Lasting Protection: Shields paint from dirt, UV rays, and contaminants for years.",
        "High-Gloss Finish: Provides a deep, reflective shine that enhances the vehicle’s appearance.",
        "Hydrophobic Properties: Water and grime slide off effortlessly, making cleaning a breeze.",
        "Reduced Maintenance: Keeps your vehicle cleaner for longer, requiring less frequent washes.",
        "Scratch Resistance: Reduces the likelihood of minor scratches from everyday use.",
      ],
      sedanPrice: "$800 (2 years) / $1100 (5 years)",
      vanPrice: "$1000 (2 years) / $1300 (5 years)",
    },
    {
      id: 3,
      title: "EXTERIOR WASH AND RAIN PROTECTION",
      description:
        "Keep your vehicle clean and shiny, even in bad weather. Our exterior wash removes grime, and the rain protection coating ensures water beads off, reducing streaks and keeping your vehicle looking spotless.",
      imageSrc: "/images/regWash&rainProt.webp",
      servicesList: [
        "Thorough Exterior Wash: Removes road grime, dust, and dirt for a spotless finish.",
        "Rain-Repellent Coating: Helps water slide off easily, preventing streaks after rain.",
        "Clay Bar Treatment: Smooths the surface by removing stubborn contaminants (if needed).",
        "Spot-Free Drying: Leaves no watermarks, giving your vehicle a clean and polished look.",
        "Extended Cleanliness: Keeps your vehicle cleaner for longer, even in wet conditions.",
      ],
      sedanPrice: "$100",
      vanPrice: "$150",
    },
    {
      id: 4,
      title: "PAINT CORRECTION",
      description:
        "Restore your vehicle’s original shine by removing scratches, swirl marks, and dull paint. Paint correction brings back vibrant color, giving your vehicle a showroom-quality finish.",
      imageSrc: "/images/paintProt.webp",
      servicesList: [
        "Scratch and Swirl Removal: Eliminates imperfections, restoring a smooth, flawless finish.",
        "Enhanced Gloss: Revives the deep, reflective shine of your vehicle’s paint.",
        "Color Restoration: Restores faded paint to its original vibrant color.",
        "Oxidation Removal: Cleans up dull or chalky paint for a refreshed appearance.",
        "Perfect Prep for Coatings: Prepares the surface for additional protection like wax or ceramic coating.",
      ],
      sedanPrice: "$400",
      vanPrice: "$600",
    },
    {
      id: 5,
      title: "FULL INTERIOR STEAM CLEAN SHAMPOO",
      description:
        "Give your vehicle’s interior a deep and thorough clean. Our steam cleaning and shampoo service removes tough stains, odors, and allergens, leaving your vehicle feeling fresh and sanitized.",
      imageSrc: "/images/steam&shampoo.webp",
      servicesList: [
        "High-Temperature Steam Cleaning: Penetrates deep to remove dirt, germs, and bacteria.",
        "Upholstery and vehiclepet Shampoo: Lifts tough stains and odors from fabrics.",
        "Leather Treatment: Cleans and conditions leather, ensuring it remains soft and protected.",
        "Targeted Stain Removal: Focuses on high-use areas with persistent stains.",
        "Complete Surface Wipe-Down: Leaves dashboards, panels, and trims spotless and refreshed.",
      ],
      sedanPrice: "$150",
      vanPrice: "$180",
    },
    {
      id: 6,
      title: "INTERIOR AND EXTERIOR DETAILING",
      description:
        "Get the ultimate detailing package that covers both the inside and outside of your vehicle. This service leaves your vehicle spotless, polished, and protected from top to bottom.",
      imageSrc: "/images/interior&exterior.webp",
      servicesList: [
        "Complete Exterior Wash: Removes dirt, grime, and road debris for a sparkling finish.",
        "Polishing and Waxing: Enhances gloss and provides a protective barrier against elements.",
        "Comprehensive Interior Vacuuming: Ensures every surface is free of dust and dirt.",
        "Interior Surface Cleaning: Cleans dashboards, door panels, and trims for a fresh look.",
        "Restores Comfort and Style: Revitalizes your vehicle inside and out, leaving it looking brand new.",
      ],
      sedanPrice: "$250",
      vanPrice: "$350",
    },
    {
      id: 7,
      title: "PAINT SEALANT & PAINT PROTECTION",
      description:
        "Extend the life of your vehicle’s paint with our high-performance sealant. This service enhances shine and protects your vehicle’s exterior from UV rays, dirt, and contaminants for an entire year.",
      imageSrc: "/images/paintSealant.jpg",
      servicesList: [
        "Surface Cleaning: Prepares the paint for smooth sealant application.",
        "Durable Paint Sealant: Protects against fading, dirt, and pollutants for up to a year.",
        "Enhanced Gloss: Adds a layer of brilliance for a stunning, reflective finish.",
        "UV Protection: Prevents paint damage from prolonged sun exposure.",
        "Easy Maintenance: Keeps your vehicle looking new with less effort.",
      ],
      sedanPrice: "$500",
      vanPrice: "$700",
    },
    {
      id: 8,
      title: "HEADLIGHT RESTORATION",
      description:
        "Improve visibility and enhance the appearance of your vehicle with headlight restoration. This service restores clarity, ensuring safer driving and giving your vehicle a polished look.",
      imageSrc: "/images/headlight.jpeg",
      servicesList: [
        "Headlight Cleaning: Removes dirt, grime, and oxidation for improved clarity.",
        "Lens Smoothing: Eliminates cloudiness and yellowing for crystal-clear headlights.",
        "Polishing and Buffing: Restores shine and brightness for better visibility.",
        "Protective Coating: Prevents future yellowing and fading for long-lasting clarity.",
        "Enhanced Safety and Style: Improves visibility while giving your vehicle a refined look.",
      ],
      sedanPrice: "$80",
      vanPrice: "$100",
    },
  ];
  
  
  

  return (
    <>
      <PageHeader
        title="Our Services"
        subtitle="Explore our range of detailing services"
        backgroundImage="/images/services.webp"
        buttonText="View our Pricing"
        buttonLink="/packages"
      />
      {serviceData.map((service) => (
        <ServiceElement key={service.id} {...service} />
      ))}
    </>
  );
};

export default Services;