// src/components/PageHeader.js
import React from "react";

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  animationClass = "zoom-in",
  buttonText,
  buttonLink,
}) => {
  return (
    <div
      className="page-header min-vh-80"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <span className="mask bg-gradient-dark opacity-6"></span>
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            {/* Apply the animation class dynamically */}
            <div className={`text-center ${animationClass}`}>
              <h1 className="text-white header-title">{title}</h1>
              <h3 className="text-white header-subtitle">{subtitle}</h3>
              {/* Generic Button */}
              <a href={buttonLink} className="btn btn-custom mt-4">
                {buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
