import React, { useState, useEffect } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Helper functions to build paths dynamically
const getThumbnailPath = (src) => `/images/thumbnails/${src.split('/').pop()}`;
const getFullImagePath = (src) => `/images/gallery-images/${src.split('/').pop()}`;

const GalleryComponent = ({ imagePaths }) => {
  const [photos, setPhotos] = useState([]); // Store photos with thumbnails
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Track loading state for lightbox

  // Generate an array of photo objects with thumbnail paths
  const generatePhotoData = async (imagePaths) => {
    const promises = imagePaths.map((src) => {
      const thumbnailSrc = getThumbnailPath(src);
      return new Promise((resolve) => {
        const img = new Image();
        img.src = thumbnailSrc;
        img.onload = () => {
          resolve({
            src: thumbnailSrc, // Thumbnail for gallery
            width: img.width,
            height: img.height,
            originalSrc: getFullImagePath(src), // Full image for lightbox
          });
        };
      });
    });

    const results = await Promise.all(promises);
    setPhotos(results);
  };

  // Load thumbnails on component mount
  useEffect(() => {
    generatePhotoData(imagePaths);
  }, [imagePaths]);

  const openLightbox = (event, { index }) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
    setLoading(true); // Show loading state until full image is loaded
  };

  const handleImageLoad = () => setLoading(false); // Hide loading indicator

  return (
    <div>
      {/* Gallery with thumbnails */}
      <Gallery photos={photos} onClick={openLightbox} direction="row" />

      {isLightboxOpen && (
        <Lightbox
          mainSrc={photos[currentImageIndex]?.originalSrc} // Full image
          nextSrc={photos[(currentImageIndex + 1) % photos.length]?.originalSrc}
          prevSrc={
            photos[(currentImageIndex + photos.length - 1) % photos.length]
              ?.originalSrc
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + photos.length - 1) % photos.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % photos.length)
          }
          onImageLoad={handleImageLoad}
          imageCaption={`Image ${currentImageIndex + 1} of ${photos.length}`}
        />
      )}

      {/* Loading Indicator */}
      {loading && isLightboxOpen && (
        <div className="lightbox-loading">
          <p>Loading...</p> {/* Optional: Replace with a spinner */}
        </div>
      )}
    </div>
  );
};

export default GalleryComponent;
