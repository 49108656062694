// src/components/AreasWeCoverMap.js
import React, { useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';

// Define map container style
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

// Center the map on a general location
const center = {
  lat: 49.0504, // Centering around Abbotsford (adjust as needed)
  lng: -122.3045,
};



const libraries = ['places']; // Optional: use libraries if you need them

const AreasWeCoverMap = ({areaMarkers}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAWxM8YwYuO6V-uVi4Mctsb0ByvoTtx0mE', // Add your API key here
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      // Initialize the map after the script is loaded
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: 9,
      });

      // Add markers using the standard google.maps.Marker API
      areaMarkers.forEach((markerData) => {
        new window.google.maps.Marker({
          position: { lat: markerData.lat, lng: markerData.lng },
          map,
          title: markerData.name,
        });
      });
    }
  }, [isLoaded]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <div className="areas-we-cover-section">
      <h2 className="section-title">Areas We Cover</h2>

      <div className="city-names">
        {areaMarkers.map((area, index) => (
          <span key={index} className="city-name">
            <i className="fas fa-map-marker-alt" style={{ color: 'red' }}></i> {area.name}
          </span>
        ))}
      </div>

      <div className="map-container">
        {/* The map will be rendered here after the script is loaded */}
        <div id="map" style={mapContainerStyle}></div>
      </div>
    </div>
  );
};

export default AreasWeCoverMap;
