// src/components/Contact.js
import React from "react";
import PageHeader from "./PageHeader";
import ContactForm from "./ContactForm"; // Import the ContactForm component

const Contact = () => {
  // Handle form submission (You can integrate email handling here)
  const handleFormSubmit = (formData) => {
    console.log('Form submitted:', formData);
    alert('Thank you for getting in touch! We will get back to you soon.');
  };

  return (
    <>
      <PageHeader
        title="Contact Us"
        subtitle="We'd love to hear from you"
        backgroundImage="/images/contact-us.webp"
        buttonText="Call Us"
        buttonLink="tel:+16043003774"
      />
      <div className="container my-5">
        <div className="page-heading slide-up">
          <h1>Get in Touch</h1>
          <p>
            If you have any questions, want to learn more about our services, or
            are ready to schedule an appointment, we're here to help. Our team
            is dedicated to providing excellent customer service and ensuring
            your vehicle receives the care it deserves. Don’t hesitate to reach
            out with any inquiries, or feel free to book a service directly
            through the form below.
          </p>

          <p>
            Whether you're interested in our detailing packages, have a specific
            request, or just need some advice, we'd love to hear from you.
            Simply fill out the form, give us a call, or drop us an email, and
            we’ll get back to you as soon as possible.
          </p>
        </div>

        <br />
        <br />

        {/* Contact Form Section */}
        <div className="contact-form-section my-5">
          <ContactForm onSubmit={handleFormSubmit} />
        </div>
      </div>
    </>
  );
};

export default Contact;
