import React, { useState } from "react";
import PageHeader from "./PageHeader";
// import PackageList from "./PackageList";

// Define your packages data here
const basicPopularPackage = {
  title: "BASIC PACKAGE",
  sedanPrice: "500",
  vanPrice: "700",
  services: [
    "Exterior Ultra Foam Wash",
    "Claybar",
    "Iron Removal",
    "Basic Paint Correction",
    "Interior Detailing",
  ],
};

const essentialPopularPackage = {
  title: "ESSENTIAL PACKAGE",
  sedanPrice: "700",
  vanPrice: "900",
  services: [
    "Exterior Ultra Foam Wash",
    "Claybar",
    "Iron Removal",
    "Basic Paint Correction",
    "Interior Detailing",
    "Wheel Detailing",
    "Paint Protection (upto 1 year)",
    "Interior Trim Shine",
    "Headlight & Taillight Restoration",
    "Advanced Paint Protection",
    "Carpet Restoration",
  ],
};

const premiumPopularPackage = {
  title: "PREMIUM PACKAGE",
  sedanPrice: "800",
  vanPrice: "1200",
  services: [
    "Exterior Ultra Foam Wash",
    "Claybar",
    "Iron Removal",
    "Basic Paint Correction",
    "Interior Detailing",
    "Wheel Detailing",
    "Paint Protection (upto 1 year)",
    "Interior Trim Shine",
    "Headlight & Taillight Restoration",
    "Advanced Paint Protection",
    "Carpet Restoration",
    "Ceramic Coating (Prices vary by coating duration)",
    "Interior Ceramic Coating",
    "Black Trim Plastic Restoration",
    "Crystal Shield Glass Ceramic Coating",
  ],
};

const basicRegularPackage = {
  title: "BASIC PACKAGE",
  sedanPrice: "150",
  vanPrice: "250",
  services: [
    "Exterior Ultra Foam Wash",
    "Interior Vaccuum",
    "Dashboard Wipe Down",
    "Console Wipe Down",
  ],
};

const essentialRegularPackage = {
  title: "ESSENTIAL PACKAGE",
  sedanPrice: "250",
  vanPrice: "350",
  services: [
    "Exterior Ultra Foam Wash",
    "Interior Vaccuum",
    "Dashboard Wipe Down",
    "Console Wipe Down",
    "Interior Shampoo",
    // "Interior & Carpet Steam Cleaning (extra charges for pet hair)",
    "Interior Trim Polish	",
    // "Engine Cleaning (extra charges)"
  ],
};
const premiumRegularPackage = {
  title: "PREMIUM PACKAGE",
  sedanPrice: "300",
  vanPrice: "400",
  services: [
    "Exterior Ultra Foam Wash",
    "Interior Vaccuum",
    "Dashboard Wipe Down",
    "Console Wipe Down",
    "Interior Shampoo",
    "Interior Steam Cleaning (extra charges for pet hair)",
    "Interior Trim Polish	",
    "Engine Cleaning (extra charges)",
  ],
};

// Group the packages into arrays for easier rendering
const popularPackageslist = [
  basicPopularPackage,
  essentialPopularPackage,
  premiumPopularPackage,
];
const regularPackageslist = [
  basicRegularPackage,
  essentialRegularPackage,
  premiumRegularPackage,
];

const PackageCard = ({
  packageInfo,
  isPremium,
  isEssential,
  selectedVehicleType,
}) => {
  const price =
    selectedVehicleType === "Sedan/SUV"
      ? packageInfo.sedanPrice
      : packageInfo.vanPrice;

  return (
    <div className={`col-lg-4 mb-lg-0 mb-4 ${isPremium ? "mx-auto" : ""}`}>
      <div
        className={`card ${
          isPremium
            ? "bg-gradient-dark shadow-lg"
            : isEssential
            ? "bg-gradient-info shadow-lg"
            : "shadow-lg"
        }`}
      >
        <span
          className={`badge ${
            isPremium
              ? "bg-primary"
              : isEssential
              ? "bg-info"
              : "bg-light text-dark"
          } w-65 mt-n2 mx-auto`}
        >
          {packageInfo.title}
        </span>
        <div
          className={`card-header text-center pt-4 pb-3 ${
            isPremium ? "bg-transparent" : ""
          }`}
        >
          <h1
            className={`font-weight-bold mt-2 ${
              isPremium ? "text-white" : isEssential ? "text-white" : ""
            }`}
          >
            <small className="text-lg align-top me-1">${price}</small>
          </h1>
        </div>
        <div className="card-body text-lg-start text-center pt-0">
          {packageInfo.services.map((service, index) => (
            <div
              className="d-flex justify-content-lg-start justify-content-center p-2"
              key={index}
            >
              <i
                className={`material-icons my-auto ${
                  isPremium ? "text-white" : isEssential ? "text-white" : ""
                }`}
              >
                done
              </i>
              <span
                className={`ps-3 ${
                  isPremium ? "text-white" : isEssential ? "text-white" : ""
                }`}
              >
                {service}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PackageList = ({ packages, selectedVehicleType }) => {
  return (
    <div className="row">
      {packages.map((pkg, index) => (
        <PackageCard
          key={index}
          packageInfo={pkg}
          selectedVehicleType={selectedVehicleType}
          isPremium={pkg.title === "PREMIUM PACKAGE"}
          isEssential={pkg.title === "ESSENTIAL PACKAGE"}
        />
      ))}
    </div>
  );
};

const Packages = () => {
  const [selectedVehicleType, setSelectedVehicleType] = useState("Sedan/SUV");

  const handleTabSwitch = (vehicleType) => {
    setSelectedVehicleType(vehicleType);
  };

  return (
    <>
      <PageHeader
        title="Pricing"
        subtitle="Affordable packages for all your detailing needs"
        backgroundImage="/images/polisher.webp"
        buttonText="Contact Us"
        buttonLink="/contact"
      />
      <div className="container my-5">
        <section>
          <div className=" bg-gradient-dark m-3 border-radius-xl">
            <div className="container pb-lg-9 pb-10 pt-7 position-relative z-index-2">
              <div className="row">
                <div className="col-md-6 mx-auto text-center">
                  <h3 className="text-white">POPULAR PACKAGES</h3>
                  <p className="note-text">
                  Note: Pricing is an estimate and is dependent on the
                  condition of the vehicle.
                </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-n10">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-4 col-md-6 col-7 mx-auto text-center">
                  <div className="nav-wrapper mt-5 position-relative z-index-2">
                    <ul
                      className="nav nav-pills nav-fill flex-row p-1"
                      id="tabs-pricing"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={`nav-link mb-0 ${
                            selectedVehicleType === "Sedan/SUV" ? "active" : ""
                          }`}
                          onClick={() => handleTabSwitch("Sedan/SUV")}
                          role="tab"
                          href="#sedan"
                          aria-selected={selectedVehicleType === "Sedan/SUV"}
                        >
                          Sedan/SUV
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link mb-0 ${
                            selectedVehicleType === "Pickup/Van" ? "active" : ""
                          }`}
                          onClick={() => handleTabSwitch("Pickup/Van")}
                          role="tab"
                          href="#van"
                          aria-selected={selectedVehicleType === "Pickup/Van"}
                        >
                          Pickup/Van
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content tab-space">
                <PackageList
                  packages={popularPackageslist}
                  selectedVehicleType={selectedVehicleType}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className=" bg-gradient-dark m-3 border-radius-xl">
            <div className="container pb-lg-9 pb-10 pt-7 position-relative z-index-2">
              <div className="row">
                <div className="col-md-6 mx-auto text-center">
                  <h3 className="text-white">REGULAR PACKAGES</h3>
                  <p className="note-text">
                  Note: Pricing is an estimate and is dependent on the
                  condition of the vehicle.
                </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-n10">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-4 col-md-6 col-7 mx-auto text-center">
                  <div className="nav-wrapper mt-5 position-relative z-index-2">
                    <ul
                      className="nav nav-pills nav-fill flex-row p-1"
                      id="tabs-pricing"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={`nav-link mb-0 ${
                            selectedVehicleType === "Sedan/SUV" ? "active" : ""
                          }`}
                          onClick={() => handleTabSwitch("Sedan/SUV")}
                          role="tab"
                          href="#sedan"
                          aria-selected={selectedVehicleType === "Sedan/SUV"}
                        >
                          Sedan/SUV
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link mb-0 ${
                            selectedVehicleType === "Pickup/Van" ? "active" : ""
                          }`}
                          onClick={() => handleTabSwitch("Pickup/Van")}
                          role="tab"
                          href="#van"
                          aria-selected={selectedVehicleType === "Pickup/Van"}
                        >
                          Pickup/Van
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content tab-space">
                <PackageList
                  packages={regularPackageslist}
                  selectedVehicleType={selectedVehicleType}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Packages;
