// src/components/ContactForm.js
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Validate the form
  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required.';
    if (!formData.phone) errors.phone = 'Phone number is required.';
    if (!formData.email) {
      errors.email = 'Email address is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid.';
    }
    if (!formData.message) errors.message = 'Message is required.';
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setIsSubmitted(true);
      const { name, phone, email, message } = formData;
      
    try {
      const templateParams = {
        name,
        phone,
        email,
        message,
      };
      await emailjs.send(
        'service_clnfj9d',
        'template_qej52ki',
        templateParams,
        'o2G-T7RNadEPB4g-a'
      );
    } catch (e) {
      console.error('Failed to send message:', e);
    }
      console.log('Form submitted', formData);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {isSubmitted ? (
        <p>Thank you for getting in touch! We'll get back to you soon.</p>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <h2 className="form-title">CONTACT US</h2>
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
            />
            {errors.name && <span className="text-danger">{errors.name}</span>}
          </div>

          <div className="form-group">
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone number"
              value={formData.phone}
              onChange={handleChange}
              className="form-control"
            />
            {errors.phone && <span className="text-danger">{errors.phone}</span>}
          </div>

          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
            />
            {errors.email && <span className="text-danger">{errors.email}</span>}
          </div>

          <div className="form-group">
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              className="form-control"
            />
            {errors.message && <span className="text-danger">{errors.message}</span>}
          </div>

          <button type="submit" className="btn btn-submit">Submit</button>
        </form>
      )}
    </>
  );
};

export default ContactForm;
